import PropTypes from 'prop-types'
import Translation from '#src/common/components/translation'
import { Container, ButtonLabel } from './styles'
import { accountSelector } from '#src/state/account-state-slice'
import { useAppSelector } from '#src/state/redux-hooks'

const ShopFinderStartOrderButton = ({
  loading = false,
  onClick,
  classes,
  index
}) => {
  const { isLoading } = useAppSelector(accountSelector)
  return (
    <div className={classes}>
      <Container
        id={`start-order-button-${index}`}
        activity={loading}
        compact
        data-testid='start-order-button'
        onClick={onClick}
        name='Start order'
        styleType='quaternary'
        disabled={isLoading}
      >
        <ButtonLabel isButtonDisabled={isLoading}>
          <Translation
            id={loading ? 'common.loading' : 'storefinder.store.startOrder'}
          />
        </ButtonLabel>
      </Container>
    </div>
  )
}

ShopFinderStartOrderButton.defaultProps = {
  classes: '',
  loading: false,
  onClick: () => {}
}

ShopFinderStartOrderButton.propTypes = {
  classes: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number
}

export default ShopFinderStartOrderButton
