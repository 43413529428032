import { useRouter } from 'next/router'
import {
  StoreInformationButton,
  StoreListItemContainer,
  SelectShopButton,
  StoreListItemContent,
  StyledMapMarker,
  WheelchairAccess
} from './styles'
import { translate } from '#src/common/components/translation'
import { storeTypes } from '#constants'
import { WheelchairIcon } from '../wheelchair-icon'
import ShopInformation from './shop-information'
import { formatDistanceToMiles } from './shop-formatter-util'
import { transformAddressToString } from '#src/common/lib/format-address'

interface StoreProps {
  address: {
    streetNumber: string
    streetName: string
    city: string
    postalCode: string
  }
  distance: string
  name: string
  todaysHours: string
  features: Record<any, any>
}

interface StoreListItemProps {
  index: number
  isTactical?: boolean
  disabled?: boolean
  onClick: (s: StoreProps, index: number) => void
  showTextOnPin?: boolean
  store: StoreProps
}

// Refactor isTactical flag - TT-1279
const StoreListItem: React.FunctionComponent<StoreListItemProps> = ({
  index,
  store,
  isTactical = true,
  onClick = () => {},
  disabled = false,
  showTextOnPin = false
}) => {
  const { locale } = useRouter()
  const pinDescription = translate(
    'storefinder.store.pin.description',
    locale,
    { index }
  )?.value
  const shopInformation = translate('storefinder.store.information', locale, {
    index
  })?.value
  const selectShop = translate('storefinder.store.selectShop', locale, {
    index
  })?.value
  const address = transformAddressToString(store.address)
  const distance = formatDistanceToMiles(store.distance, locale)
  const openTime = store.todaysHours
  const selectShopText = isTactical ? selectShop : distance
  const hasWheelchairAccess = store.features.wheelchairAccess
    ? 'storefinder.store.has-wheelchair-access'
    : 'storefinder.store.has-not-wheelchair-access'
  const wheelchairAccess = translate(hasWheelchairAccess, locale)?.value
  const isVeggiePret = store.features.storeType === storeTypes.veggiePret

  const handleClick = e => {
    e.stopPropagation()
    if (!disabled) {
      onClick(store, index)
    }
  }

  return (
    <StoreListItemContainer>
      <StoreListItemContent
        disabled={disabled}
        data-testid='store-list-item'
        onClick={handleClick}
      >
        <StyledMapMarker
          title={pinDescription}
          showTextOnPin={showTextOnPin}
          isVeggiePret={isVeggiePret}
        >
          {index}
        </StyledMapMarker>
        <ShopInformation
          name={store.name}
          address={address}
          openTime={openTime}
          footer={
            isTactical ? (
              <WheelchairAccess>
                <WheelchairIcon />
                {wheelchairAccess}
              </WheelchairAccess>
            ) : (
              <StoreInformationButton
                onClick={handleClick}
                styleType='tertiary'
                isVeggiePret={isVeggiePret}
              >
                {shopInformation}
              </StoreInformationButton>
            )
          }
          rightContent={
            isTactical ? (
              <SelectShopButton
                data-testid={`select-shop-button-${index}`}
                styleType='tertiary'
                onClick={handleClick}
              >
                <span>{selectShopText}</span>
                <span className='sr-only'>{store.name}</span>
              </SelectShopButton>
            ) : (
              selectShopText
            )
          }
        />
      </StoreListItemContent>
    </StoreListItemContainer>
  )
}
export default StoreListItem
