import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  AriaLive,
  Button,
  Grid,
  GridArea,
  Input,
  getScreenSizes
} from '@pretamanger/component-library'
import Map from '../map'

export const ShopFinderPageHeading = styled('h1')`
  ${tw`hidden sr-only`}
`

export const ShopSearchGrid = styled(Grid)`
  ${tw`gap-0 mx-0`}
  grid-template-rows: auto auto 1fr;
`

export const SearchGridArea = styled(GridArea)`
  ${tw`px-0 xl:pr-8 pt-8`}
`

export const MessageGridArea = styled(GridArea)``

export const ViewSelectionGridArea = styled(GridArea)`
  &&& {
    ${({ hidden }) => (hidden ? tw`hidden` : tw`lg:hidden`)}
  }
`

export const ListGridArea = styled(GridArea)`
  ${tw`h-auto`}

  ${({ hidden }) => (hidden ? tw`hidden` : '')}
  ${({ active }) =>
    active ? tw`block flex-1 relative z-0` : tw`lg:block! xs:hidden`}
  ${({ active }) => (active ? tw`flex flex-col` : '')}
`

export const MapGridArea = styled(GridArea)`
  @media (max-width: ${getScreenSizes().sm}px) {
    height: calc(100vh - 2rem);
  }

  @media (max-width: ${getScreenSizes().lg}px) {
    ${({ hidden }) => (hidden ? tw`hidden` : '')}
  }

  ${tw`not-sr-only bg-grey-100 h-screen w-full lg:ml-8 sticky top-0`}
  ${({ active }) => (active ? tw`block h-screen` : tw`lg:block! xs:hidden`)}
`
export const SelectShopButtonContainer = styled(AriaLive)`
  ${tw`mt-2 text-center`}
`
export const SearchForm = styled('form')`
  ${tw`flex`}
`

export const LocationInput = styled(Input)`
  ${tw`w-full`}
  input {
    height: 48px !important;
    border-radius: 0;
    -webkit-appearance: none;
    border-right: 0;
  }
`

export const SearchContainer = styled('div')`
  ${tw`lg:border-b lg:border-grey-200`}
`

export const SearchButton = styled(Button)`
  ${tw`px-4 self-end h-12 mb-4`}
  ${tw`rounded-l-none`}
  margin-top: 1.75rem;
`

export const CurrentLocationButton = styled(Button)`
  ${tw`mb-4 p-0`}

  svg {
    ${tw`ml-0`}
  }
`

export const ShopMap = styled(Map)`
  ${tw`not-sr-only bg-grey-100 h-full w-full`}
`

export const ShopList = styled('ol')``

export const ShopListDescription = styled('div')`
  ${tw`w-full pt-6 pb-4 text-sm text-grey-500 leading-tight leading-tight`}

  @media (max-width: ${getScreenSizes().lg}px) {
    ${tw`p-2`}
  }
`

export const ViewSelector = styled('button')`
  ${tw`not-sr-only border-none py-4 text-center w-1/2 leading-normal`}
  ${({ active }) =>
    active
      ? tw`text-pretRed-700 sticky top-0 border-b-2 border-pretRed-700 border-solid`
      : tw`text-grey-500 border-b border-grey-200 border-solid`}
`
