import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  AriaLive,
  Button,
  Grid,
  GridArea,
  Heading,
  Input,
  getScreenSizes
} from '@pretamanger/component-library'

export const SearchFormHeading = styled(Heading)`
  ${tw`pb-4 font-normal lg:pb-5`}
  letter-spacing: -1.1px;
  font-size: 2rem;
`

export const ShopFinderPageHeading = styled('h1')`
  ${tw`hidden sr-only`}
`

export const ShopSearchGrid = styled(Grid)`
  ${tw`gap-0 mx-0`}
  grid-template-rows: auto auto 1fr;

  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`mx-0`}
  }
`

export const SearchGridArea = styled(GridArea)`
  ${tw`px-0 lg:pt-12`}
  padding-top: 1.875rem;
`

export const MessageGridArea = styled(GridArea)``

export const ViewSelectionGridArea = styled(GridArea)`
  @media (max-width: ${getScreenSizes().sm}px) {
    width: 100vw;
    margin-left: -2rem;
  }

  &&& {
    ${({ hidden }) => (hidden ? tw`hidden` : tw`lg:hidden`)}
  }
`

export const ListGridArea = styled(GridArea)`
  ${tw`h-auto`}

  ${({ hidden }) => (hidden ? tw`hidden` : '')}
  
  ${({ active }) =>
    active ? tw`block flex-1 relative z-0` : tw`lg:block! xs:hidden`}
  ${({ active }) => (active ? tw`flex flex-col` : '')}
`

export const MapGridArea = styled(GridArea)`
  @media (max-width: ${getScreenSizes().sm}px) {
    height: calc(100vh - 2rem);
    width: 92vw;
    margin-left: -1rem;
  }

  @media (max-width: ${getScreenSizes().lg}px) {
    ${({ hidden }) => (hidden ? tw`hidden` : '')}
  }

  ${tw`not-sr-only bg-grey-100 h-screen w-full lg:ml-8 sticky top-0`}
  ${({ active }) => (active ? tw`block h-screen` : tw`lg:block! xs:hidden`)}
`

export const SelectShopButtonContainer = styled(AriaLive)`
  ${tw`mt-2 text-center`}
`
export const SearchForm = styled('form')`
  ${tw`flex`}
`

export const LocationInput = styled(Input)`
  ${tw`w-full`}
  input {
    height: 48px !important;
    border-radius: 0;
    -webkit-appearance: none;
  }
`

export const SearchContainer = styled('div')`
  @media (min-width: ${getScreenSizes().lg}px) {
    ${tw`border-b border-grey-200`}
  }
`

export const SearchButton = styled(Button)`
  ${tw`px-4 self-end h-12 mb-4 `}
  ${tw`rounded-l-none`}
`

export const CurrentLocationButton = styled(Button)`
  ${tw`mb-4 p-0`}

  svg {
    ${tw`ml-0`}
  }
`

export const ShopList = styled('ol')``

export const ErrorWrapper = styled('div')`
  ${tw`w-full pb-3 text-sm text-grey-500 leading-tight leading-tight xs:border-t xs:border-grey-200 md:border-none`}
  padding-top: 1.875rem;
`

export const ErrorHeading = styled(Heading)`
  ${tw`text-black font-normal leading-relaxed lg:text-2xl`}

  margin-bottom: 0.625rem;
  letter-spacing: -0.75px;
`

export const ErrorDescription = styled('div')`
  ${tw`text-black tracking-xs`}
`

export const ViewSelector = styled('button')`
  ${tw`not-sr-only border-t border-solid border-grey-200 py-4 text-center w-1/2 leading-normal`}

  ${({ mapTab }) => mapTab && tw`border-r`}
  
  ${({ active }) =>
    active
      ? tw`text-pretRed-700 sticky top-0 border-b-2`
      : tw`text-grey-500 border-b`}

  border-bottom-color: ${({ active }) => (active ? '#9F1B32' : '#D9D9D9')};
`

export const ListViewDescription = styled('div')`
  ${tw`pt-6`}
  min-height: 40vh
`

export const UberallContainer = styled('div')`
  .ubsf_locations-list-item-top .ubsf_right-side {
    display: none !important;
  }
  .ubsf_info-window-content-header .ubsf_info-window-content-picture {
    display: none !important;
  }
  #store-finder-widget .custom-pin-default {
    background: none !important;
  }
  #store-finder-widget .custom-pin-default .custom-inner-pin-logo {
    height: 100% !important;
  }
  #store-finder-widget .custom-pin-highlighted {
    background: none !important;
  }
  #store-finder-widget .custom-pin-highlighted .custom-inner-pin-logo {
    height: 100% !important;
  }
  #store-finder-widget .ubsf_location-page-map-pin {
    background: none !important;
  }
  #store-finder-widget
    .ubsf_location-page-map-pin
    .ubsf_location-page-map-pin-logo {
    height: 100% !important;
  }
`
