import styled from '@emotion/styled'
import tw from 'twin.macro'
import { getScreenSizes } from '@pretamanger/component-library'
import ShopFinderMapMarker from '#src/shop-finder/components/shop-finder-map-marker'

export const Wrapper = styled('li')`
  ${tw`py-2 border-grey-200 border-b last:border-b-0`}
`

export const Content = styled('div')`
  ${tw`grid py-2 gap-8 outline-none`}
  grid-template-columns: 2rem 1fr;
  &:last-of-type {
    border: 0;
  }
  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`gap-4`}
    grid-template-columns: 1.5rem 1fr;
  }
`

export const MapMarker = styled(ShopFinderMapMarker)`
  ${tw`h-10 w-6 md:h-12 md:w-8`}
  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`row-start-1 row-end-1`}
  }
`
