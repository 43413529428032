import PropTypes from 'prop-types'
import { Heading, Chevron } from '@pretamanger/component-library'
import Translation from '#src/common/components/translation'
import { RegionListButton } from './styles'

const ShopFinderRegionList = ({ regions, onSelectRegion }) => {
  const handleSelectRegion = location => () => {
    onSelectRegion(location)
  }

  if (!regions.length) return null

  return (
    <div>
      <Heading className='mt-6 mb-2' level='h1' styleOverride='headingXs'>
        <Translation id='storefinder.search.searchByRegion' />
      </Heading>
      {regions.map((region, index) => (
        <RegionListButton
          key={`${region.title}-${index}`}
          onClick={handleSelectRegion(region.location)}
        >
          <p className='text-lg leading-xl'>{region.title}</p>
          <Chevron type='right' />
        </RegionListButton>
      ))}
    </div>
  )
}

ShopFinderRegionList.propTypes = {
  regions: PropTypes.array,
  onSelectRegion: PropTypes.func.isRequired
}

ShopFinderRegionList.defaultProps = {
  regions: []
}

export default ShopFinderRegionList
