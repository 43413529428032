export const WheelchairIcon = () => (
  <svg
    width='15'
    height='20'
    viewBox='0 0 15 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.0608 8.46526L6.8396 8.66149V7.03687L11.0608 7.23296V8.46526Z'
      fill='#404040'
    />
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='3'
      y='0'
      width='5'
      height='5'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.45929 0H7.90386V4.50135H3.45929V0Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.68308 4.50136C6.90857 4.50136 7.90386 3.49202 7.90386 2.24901C7.90386 1.006 6.90857 -3.05176e-05 5.68308 -3.05176e-05C4.4555 -3.05176e-05 3.45929 1.006 3.45929 2.24901C3.45929 3.49202 4.4555 4.50136 5.68308 4.50136Z'
        fill='#404040'
      />
    </g>
    <mask
      id='mask1'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='3'
      y='5'
      width='12'
      height='14'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.33966 5.00793H14.3628V18.3976H3.33966V5.00793Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask1)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.63273 8.6928L6.02175 8.29279L5.40777 8.69703L5.62554 8.0046L5.0645 7.60367H5.76581L6.02175 6.84204L6.28071 7.60367H6.98646L6.41496 8.0046L6.63273 8.6928ZM11.8648 11.1111H7.74424L8.12999 5.28303L5.73482 5.00793L3.33966 5.28303L4.67901 13.6079H6.03443H7.9411H10.9678L13.2161 18.3976L14.3628 17.9249L11.8648 11.1111Z'
        fill='#404040'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.4932 14.3915L9.75712 14.3941C9.75739 14.4125 9.75791 14.431 9.75791 14.4494C9.75791 16.8354 7.84026 18.7765 5.48327 18.7765C3.12627 18.7765 1.20863 16.8354 1.20863 14.4494C1.20863 12.8208 2.10235 11.3997 3.41922 10.6615L3.2115 9.39838C1.31856 10.2741 0 12.2087 0 14.4494C0 17.51 2.45974 20 5.48327 20C8.23294 20 10.5161 17.9405 10.9072 15.2656L10.4932 14.3915Z'
      fill='#404040'
    />
  </svg>
)
