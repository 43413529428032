import qs from 'qs'
import geocodeByCountry from '../../../../../geocode-by-country.json'
import platformClient from '#lib/platform-client'
import { localeHeader } from '#constants'

export const storeSearchByAddress = async ({
  address,
  market,
  locale,
  filterCollection
}) => {
  const query = qs.stringify({ address, market, filterCollection })
  return platformClient(`/shops/by-address?${query}`, {
    headers: {
      [localeHeader]: locale
    }
  })
}

export const storeSearchByLocation = async ({
  lat,
  lng,
  locale,
  filterCollection
}) => {
  const query = qs.stringify({ lat, lng, filterCollection })
  return platformClient(`/shops/by-location?${query}`, {
    headers: {
      [localeHeader]: locale
    }
  })
}

export const getGeocodeByLocale = locale => {
  const geocode = geocodeByCountry[locale].geometry
  const center = geocode.location
  const fitBounds = {
    ne: geocode.viewport.northeast,
    sw: geocode.viewport.southwest
  }
  return {
    center,
    fitBounds
  }
}

export const getAllShops = async ({ locale }) => {
  return platformClient(`/shops`, {
    headers: {
      [localeHeader]: locale
    }
  })
}
