import { resultFilterTypes } from '#src/shop-finder/components/shop-finder-results-filter'
import equals from 'ramda/src/equals'

export const exitFullScreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen()
  } else if (document.mozFullScreenElement) {
    document.mozCancelFullScreen()
  } else if (document.webkitFullscreenElement) {
    document.webkitExitFullscreen()
  } else if (document.msFullscreenElement) {
    document.msExitFullscreen()
  }
}

export const getErrorMessageId = ({
  isSearching,
  hasSearched,
  hasApiThrownError,
  shopsLength,
  filteredShopsLength,
  activeFilters
}) => {
  if (isSearching || !hasSearched) return

  if (hasApiThrownError) {
    return 'storefinder.search.apierror'
  }

  if (shopsLength === 0) {
    return 'storefinder.search.no.results.description'
  }

  if (filteredShopsLength === 0) {
    // order ahead filter active
    if (equals(activeFilters, [resultFilterTypes.orderAhead])) {
      return 'storefinder.search.noOrderAheadShops'
    }

    // veggie pret filter active
    if (equals(activeFilters, [resultFilterTypes.veggiePret])) {
      return 'storefinder.search.noVeggiePretShops'
    }

    // order ahead && veggie pret filter active
    return 'storefinder.search.noOrderAheadOrVeggiePretShops'
  }
}

export const getShopFinderRegionsFromMarket = (market, locale) => {
  const localeObject = market?.locales?.find(({ code }) => code === locale)
  return localeObject?.regions ?? []
}
