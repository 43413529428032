import PropTypes from 'prop-types'
import {
  ShopOpenTime,
  ShopAddress,
  ShopInformationContainer,
  ShopInformationLeftContainer,
  ShopInformationRightContainer
} from './styles'
import { Heading } from '@pretamanger/component-library'

const ShopInformation = ({
  address,
  footer,
  isModal,
  name,
  openTime,
  rightContent
}) => (
  <ShopInformationContainer isModal={isModal}>
    <ShopInformationLeftContainer>
      <Heading level='h2' styleOverride='heading2XsLight'>
        {name}
      </Heading>
      <ShopAddress>{address}</ShopAddress>
      <ShopOpenTime>{openTime}</ShopOpenTime>
      {footer}
    </ShopInformationLeftContainer>
    <ShopInformationRightContainer isModal={isModal}>
      {rightContent}
    </ShopInformationRightContainer>
  </ShopInformationContainer>
)

ShopInformation.propTypes = {
  address: PropTypes.string.isRequired,
  footer: PropTypes.node.isRequired,
  isModal: PropTypes.bool,
  name: PropTypes.string.isRequired,
  openTime: PropTypes.string.isRequired,
  rightContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired
}

ShopInformation.defaultProps = {
  isModal: false
}

export default ShopInformation
