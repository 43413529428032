const small = [
  [{ name: 'search', start: 1, span: 4 }],
  [{ name: 'search-result-message', start: 1, span: 4 }],
  [{ name: 'view-selection', start: 1, span: 4 }],
  [{ name: 'list', start: 1, span: 4 }],
  [{ name: 'map', start: 1, span: 4 }]
]

const medium = [
  [{ name: 'search', start: 1, span: 6 }],
  [{ name: 'search-result-message', start: 1, span: 6 }],
  [{ name: 'view-selection', start: 1, span: 6 }],
  [{ name: 'list', start: 1, span: 6 }],
  [{ name: 'map', start: 1, span: 6 }]
]

const large = [
  [
    { name: 'search', start: 1, span: 6 },
    { name: 'map', start: 7, span: 6 }
  ],
  [
    { name: 'search-result-message', start: 1, span: 6 },
    { name: 'map', start: 7, span: 6 }
  ],
  [
    { name: 'list', start: 1, span: 6 },
    { name: 'map', start: 7, span: 6 }
  ]
]

const shopFinderGridAreas = {
  xs: small,
  sm: medium,
  md: medium,
  lg: large,
  xl: large,
  '2xl': large
}

export default shopFinderGridAreas
