import { useContext, useState } from 'react'
import { OrderAheadBagContext } from '#src/common/context/order-ahead-bag'
import { useRouter } from 'next/router'
import { fireFeatureClickEvent } from '#src/common/lib/events/publishers/analytics/fire-events'
import { getCollectionPath } from '#src/common/lib/get-collection-path'
import { accountSelector } from '#src/state/account-state-slice'
import { useAppSelector } from '#src/state/redux-hooks'

export const useStoreSelection = () => {
  const { push, locale } = useRouter()
  const collectionPath = getCollectionPath(locale)
  const path = `${collectionPath}/collection-timeslots`
  const { updateFulfillmentDetails } = useContext(OrderAheadBagContext)
  const [updating, setUpdating] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const { isLoading: isAuthLoading } = useAppSelector(accountSelector)

  const selectShopForOrderAhead = async (shop, position = 'top') => {
    if (updating || isAuthLoading) return

    setUpdating(true)
    setActiveItem(shop.id)
    await updateFulfillmentDetails({
      fulfillingChannelKey: shop.id,
      orderType: 'collection'
    })
    fireFeatureClickEvent({
      type: 'widget',
      name: shop.name,
      position,
      destination: `${window.location.origin}/${locale}/${path}`
    })
    await push(`/${path}`, `/${locale}/${path}`)
  }

  return {
    activeItem,
    updating,
    selectShopForOrderAhead,
    isAuthLoading
  }
}
