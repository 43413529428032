import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Button, Clock } from '@pretamanger/component-library'

export const Wrapper = styled('div')`
  ${tw``}
  ${props => props.isModal && tw`items-end`}
`

export const ShopAddress = styled('p')`
  ${tw`text-sm leading-sm font-normal text-grey-500`}
`

export const ShopOpenWrapper = styled('p')`
  ${tw`font-medium text-sm leading-sm text-grey-700 tracking-sm`}
  ${props => props.closed && tw`text-grey-500`}
`

export const ShopOpenTime = styled('span')`
  ${tw`font-normal text-sm leading-sm text-grey-700 tracking-sm`}
`

export const OrderAheadWrapper = styled('div')`
  ${tw`bg-grey-50 py-1 pr-3 pl-2 border border-grey-50 h-8 flex items-center`}
  white-space: nowrap;
  ${props => props.isModal && tw`w-full`}
`

export const OrderAheadContainer = styled('div')`
  ${tw`flex items-center justify-between xs:flex-wrap bg-grey-50 sm:bg-transparent`}
`

export const OrderAheadClock = styled(Clock)`
  ${tw`inline px-1 cursor-pointer w-4 h-4 p-0 mr-1`}
`

export const OrderAheadTime = styled('p')`
  ${tw`inline-block ml-2 text-sm text-grey-700 m-0 leading-5`}
`

export const VeggiePretBadge = styled('span')`
  ${tw`flex items-center justify-center w-4 h-4 rounded-full bg-veggiePretGreen text-sm leading-sm ml-1 text-white md:flex sm:hidden`}
`

export const ShopInfoButton = styled(Button)`
  ${tw`text-pretRed-800 mt-3 outline-none focus:shadow-outline float-left`}
  svg {
    ${tw`hidden`}
  }
  span {
    ${tw`text-sm leading-sm`}
  }
`
