import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { storeProps } from '@proptypes'
import { WheelChair, Wifi, Seat } from '@pretamanger/component-library'
import { translate } from '#src/common/components/translation'
import { transformAddressToString } from '#src/common/lib/format-address'
import {
  formatStoreWeekTradingHours,
  sortTradingHoursMondayToSunday
} from '#src/shop-finder/util/dates'
import { shopSeatingNoSeats } from '#constants'

import StartOrderButton from '#src/shop-finder/components/shop-finder-start-order-button'

import {
  ShopTitleContainer,
  ShopDetailsContent,
  ShopDetailsCol,
  ShopName,
  ShopAddress,
  ShopPhoneNumber,
  Section,
  SectionContent,
  SectionTitle,
  ShopTradingHoursList,
  ShopFeature,
  ShopFeatureIconContainer,
  SectionDivider,
  OrderAheadClock
} from './styles'

const ShopFinderShopDetails = ({
  shop,
  loading,
  onClickStartOrder,
  displayClickAndCollect
}) => {
  const { locale } = useRouter()
  const address = transformAddressToString(shop.address)
  const tradingHours = formatStoreWeekTradingHours(shop.tradingHours, locale)
  const tradingHoursSorted = sortTradingHoursMondayToSunday(tradingHours)
  const hasFacilities =
    shop.features.wheelchairAccess ||
    shop.features.wifi ||
    shop.features.seating !== shopSeatingNoSeats

  const handleStartOrder = () => {
    onClickStartOrder(shop)
  }

  return (
    <div>
      <ShopTitleContainer>
        <ShopName level='h2' styleOverride='headingXs'>
          {shop.name}
        </ShopName>
        <ShopAddress>{address}</ShopAddress>
        <ShopPhoneNumber>
          Tel: <a href={`tel:${shop.contact.phone}`}>{shop.contact.phone}</a>
        </ShopPhoneNumber>
      </ShopTitleContainer>
      <ShopDetailsContent>
        <ShopDetailsCol>
          <Section>
            <SectionTitle>
              {
                translate('storefinder.storetimings.tradingHours', locale)
                  ?.value
              }
            </SectionTitle>
            <SectionContent>
              <ShopTradingHoursList>
                {tradingHoursSorted.map(tradingHour => (
                  <li key={tradingHour.day}>
                    <span>{tradingHour.day}</span>
                    <span>{tradingHour.tradingHour}</span>
                  </li>
                ))}
              </ShopTradingHoursList>
            </SectionContent>
          </Section>
        </ShopDetailsCol>
        <ShopDetailsCol>
          {displayClickAndCollect ? (
            <Section>
              <SectionTitle>
                <OrderAheadClock colour='grey-700' />{' '}
                {shop.orderAheadNextAvailable}
              </SectionTitle>
              <SectionContent className='pl-6 pb-1'>
                {shop.isCurrentlyAvailableForOA ? (
                  <StartOrderButton
                    classes='inline-flex'
                    loading={loading}
                    onClick={handleStartOrder}
                  />
                ) : null}
              </SectionContent>
            </Section>
          ) : null}
          {displayClickAndCollect && hasFacilities && <SectionDivider />}
          {hasFacilities && (
            <>
              <Section>
                <SectionTitle>
                  {translate('storefinder.store.facilities', locale)?.value}
                </SectionTitle>
                <SectionContent>
                  {shop.features.wheelchairAccess && (
                    <ShopFeature>
                      <ShopFeatureIconContainer>
                        <WheelChair colour='grey-900' />
                      </ShopFeatureIconContainer>
                      <span>
                        {
                          translate(
                            'storefinder.store.wheelchairaccess',
                            locale
                          )?.value
                        }
                      </span>
                    </ShopFeature>
                  )}
                  {shop.features.wifi && (
                    <ShopFeature>
                      <ShopFeatureIconContainer>
                        <Wifi colour='grey-900' />
                      </ShopFeatureIconContainer>
                      <span>
                        {
                          translate('storefinder.store.wifiaccess', locale)
                            ?.value
                        }
                      </span>
                    </ShopFeature>
                  )}
                  {shop.features.seating !== shopSeatingNoSeats && (
                    <ShopFeature>
                      <ShopFeatureIconContainer>
                        <Seat colour='grey-900' />
                      </ShopFeatureIconContainer>
                      <span>
                        {translate('storefinder.store.seating', locale)?.value}
                      </span>
                    </ShopFeature>
                  )}
                </SectionContent>
              </Section>
            </>
          )}
        </ShopDetailsCol>
      </ShopDetailsContent>
    </div>
  )
}

ShopFinderShopDetails.propTypes = {
  shop: storeProps.isRequired,
  loading: PropTypes.bool,
  onClickStartOrder: PropTypes.func.isRequired,
  displayClickAndCollect: PropTypes.bool
}

ShopFinderShopDetails.defaultProps = {
  loading: false,
  displayClickAndCollect: false
}

export default ShopFinderShopDetails
