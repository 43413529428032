import { translate } from '#src/common/components/translation'

const metresToMilesMultiplier = 0.000621371
const metresToKmMultiplier = 0.001

const localesUsingKm = ['fr-FR', 'zh-HK', 'en-HK']

const formatDistanceToMiles = (metres, locale) => {
  const miles = (metres * metresToMilesMultiplier).toFixed(1)
  return translate('storefinder.store.distance.miles.shortened', locale, {
    count: miles
  })?.value
}

const formatDistanceToKm = (metres, locale) => {
  const kilometres = (metres * metresToKmMultiplier).toFixed(1)
  return translate('storefinder.store.distance.kilometres', locale, {
    count: kilometres
  })?.value
}

export const formatDistanceToLocaleValue = (metres, locale) => {
  if (localesUsingKm.includes(locale)) {
    return formatDistanceToKm(metres, locale)
  }
  return formatDistanceToMiles(metres, locale)
}
