import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Button, Checkbox } from '@pretamanger/component-library'

export const FilterButton = styled('button')`
  ${tw`text-sm cursor-pointer text-pretRed-700 block focus:outline-none focus:shadow-outline`}
  svg {
    display: inline-block;
  }
`

export const ApplyButton = styled(Button)`
  ${tw`font-normal py-1 px-8 leading-sm text-sm mr-1`}
  span {
    ${tw`text-sm`}
  }
`

export const ClearButton = styled('button')`
  ${tw`py-1 px-8 text-sm cursor-pointer text-pretRed-700 block focus:outline-none focus:shadow-outline`}
`

export const FilterCheckbox = styled(Checkbox)`
  label span {
    ${tw`md:text-sm text-xs`}
  }

  & > div {
    ${tw`mr-3`}
  }
`
