import { useCallback, useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getMarketDetailsFromLocale } from '#lib/get-market'
import { useSwitch } from '#hooks'
import {
  storeSearchByAddress,
  storeSearchByLocation
} from '#src/common/lib/api/shop'

const useShopSearch = (defaultShops = [], filterCollection = false) => {
  const isCancelled = useRef(false)
  const { locale } = useRouter()
  const [hasSearched, toggleHasSearched] = useSwitch(false)
  const [isSearching, startSearching, stopSearching] = useSwitch(false)
  const [hasApiThrownError, apiThrownError, resetHasApiThrownError] =
    useSwitch(false)
  const [hasResultsByLocation, setHasResultsByLocation] = useState(false)
  const [address, setAddress] = useState('')
  const [shops, setShops] = useState(defaultShops)

  useEffect(() => {
    return () => {
      isCancelled.current = true
    }
  }, [])

  const preSearchAction = () => {
    startSearching()
    toggleHasSearched()
    setShops([])
    setHasResultsByLocation(false)
    resetHasApiThrownError()
  }

  const postSearchAction = results => {
    if (isCancelled.current) return
    setShops(results)
  }

  const reset = () => {
    setShops([])
    setHasResultsByLocation(false)
    apiThrownError()
  }

  const search = useCallback(
    async e => {
      e.preventDefault()
      preSearchAction()
      try {
        const market = getMarketDetailsFromLocale(locale)
        const address = e.target.address.value
        setAddress(address)
        const results = await storeSearchByAddress({
          address,
          market: market.id,
          locale,
          filterCollection
        })
        postSearchAction(results)
      } catch (e) {
        reset()
      } finally {
        stopSearching()
      }
    },
    // todo see https://pretamanger.atlassian.net/browse/T2D-824
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddress, setShops]
  )

  const searchByCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        await searchByLatLong({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchByLatLong = useCallback(
    async ({ lat, lng }) => {
      preSearchAction()
      setAddress('')
      try {
        const results = await storeSearchByLocation({
          lat,
          lng,
          locale,
          filterCollection
        })
        setHasResultsByLocation(true)
        postSearchAction(results)
      } catch (e) {
        reset()
      } finally {
        stopSearching()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setHasResultsByLocation, setShops]
  )

  return {
    search,
    searchByLatLong,
    searchByCurrentLocation,
    isSearching,
    hasResultsByLocation,
    hasSearched,
    hasApiThrownError,
    address,
    shops
  }
}

export default useShopSearch
