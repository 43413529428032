import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Button, Heading } from '@pretamanger/component-library'

export const ShopTitleContainer = styled('div')`
  ${tw`px-5`}
`
export const ShopDetailsContent = styled('div')`
  ${tw`flex flex-wrap mt-6`}
`

export const ShopDetailsCol = styled('div')`
  ${tw`w-full md:w-2/5 px-5 mt-4`}

  &:first-of-type {
    ${tw`md:border-r md:border-grey-100 md:w-3/5`}
  }
`

export const ShopName = styled(Heading)`
  ${tw`mt-4`}
`

export const ShopAddress = styled('div')`
  ${tw`text-sm leading-sm text-grey-500`}
`

export const Section = styled('section')`
  ${tw`mt-4 mb-2`}

  &:first-of-type {
    ${tw`mt-0`}
  }
`

export const SectionContent = styled('div')`
  ${tw`my-3`}
`

export const SectionTitle = styled('div')`
  ${tw`text-sm leading-sm text-grey-500`}
`

export const ShopTradingHoursList = styled('ul')`
  li {
    ${tw`list-none bg-white p-2 flex justify-between`}
  }

  li:nth-of-type(even) {
    ${tw`bg-grey-100`}
  }
`

export const ShopFeature = styled('div')`
  ${tw`text-grey-900 flex py-2`}
  span {
    ${tw`ml-2`}
  }
`
export const ShopFeatureIconContainer = styled('div')`
  ${tw`flex w-8 justify-center`}
`
export const ShopContactInfoButton = styled(Button)`
  &&& {
    ${tw`inline-block`}
    ${({ isVeggiePret }) => (isVeggiePret ? tw`text-veggiePretGreen` : '')}
    
    span {
      color: currentColor;
    }
  }
`
