import { useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import {
  AriaLive,
  getColours,
  Location,
  Modal,
  Search as SearchIcon
} from '@pretamanger/component-library'

import Translation, { translate } from '#src/common/components/translation'
import { location, shopFinderRegion } from '#proptypes/index'
import { storeTypes } from '#constants'
import { useResize } from '#hooks'
import { getErrorMessageId } from '#src/shop-finder/util/ui'

import ShopFinderShopListItem from './components/shop-finder-shop-list-item'
import ShopFinderMapMarker from '#src/shop-finder/components/shop-finder-map-marker'
import ShopFinderMapMarkerModal from '#src/shop-finder/components/shop-finder-map-marker-modal'
import ShopFinderShopDetails from '#src/shop-finder/components/shop-finder-shop-details'
import ShopFinderMap from '#src/shop-finder/components/shop-finder-map'
import ShopFinderResultsFilter, {
  defaultFilterValues,
  resultFilterTypes
} from '#src/shop-finder/components/shop-finder-results-filter'
import ShopFinderRegionList from '#src/shop-finder/components/shop-finder-region-list'

import useShopSearch from '#src/shop-finder/hooks/use-shop-search'
import useShopModal from '#src/shop-finder/hooks/use-shop-modal'
import useShopMarkerModal from '#src/shop-finder/hooks/use-shop-marker-modal'
import useShopSelection from '#src/shop-finder/hooks/use-shop-selection'

import {
  CurrentLocationButton,
  ErrorHeading,
  ErrorDescription,
  ErrorWrapper,
  ListGridArea,
  ListViewDescription,
  LocationInput,
  MapGridArea,
  MessageGridArea,
  SearchButton,
  SearchContainer,
  SearchForm,
  SearchFormHeading,
  SearchGridArea,
  ShopFinderPageHeading,
  ShopList,
  ShopSearchGrid,
  ViewSelectionGridArea,
  ViewSelector
} from './styles'

import shopFinderGridAreas from '#src/shop-finder/util/grid'
import { useAsync } from 'react-use'

const ShopFinder = ({
  displayClickAndCollect,
  displayClickAndCollectShopsOnly,
  defaultCenter,
  defaultFitBounds,
  defaultFilters,
  inPagePromo,
  googleMapsJavaScriptApiKey,
  localeRegions,
  showPOI,
  showFilterMenu
}) => {
  const { locale } = useRouter()
  const {
    search,
    searchByLatLong,
    searchByCurrentLocation,
    isSearching,
    hasSearched,
    hasApiThrownError,
    address,
    shops
  } = useShopSearch([], displayClickAndCollectShopsOnly)

  const { isShopModalOpen, shopForShopModal, showShopModal, hideShopModal } =
    useShopModal()

  const {
    isMarkerModalOpen,
    shopIndexForMarkerModal,
    shopForMarkerModal,
    showMarkerModal,
    hideMarkerModal
  } = useShopMarkerModal()

  const { activeItem, updating, selectShopForOrderAhead } = useShopSelection()

  const [view, setView] = useState('map')
  const [activeFilters, setActiveFilters] = useState(defaultFilters)
  const [mapDimension, setMapDimension] = useState(null)
  const mapContainerRef = useRef()

  const setMapWidthAndHeight = () => {
    mapContainerRef.current &&
      setMapDimension({
        width: mapContainerRef.current.offsetWidth,
        height: mapContainerRef.current.offsetHeight
      })
  }

  useEffect(() => {
    setMapWidthAndHeight()
  }, [shops, view])

  useEffect(() => {
    const contentContainer = document.querySelector('main#main-content')
    contentContainer.classList.add('shop-search-content')
    return () => contentContainer.classList.remove('shop-search-content')
  }, [])

  useAsync(async () => {
    await searchByCurrentLocation()
  }, [])

  useResize(() => {
    setMapWidthAndHeight()
  })

  const getCenter = useCallback(() => {
    if (shopForMarkerModal) {
      return shopForMarkerModal.location
    }
    if (!shops.length) {
      return defaultCenter
    }

    if (shops.length > 0) {
      return shops[0].location
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopForMarkerModal, shops])

  const showShopMarkerPopup = newShopIndex => shop => {
    showMarkerModal(newShopIndex, shop)
  }

  const shopSearch = e => {
    hideShopModal()
    hideMarkerModal()
    setActiveFilters(defaultFilters)
    search(e)
  }

  const storeHeadingtext = translate('storefinder.heading', locale)?.value

  const filteredShops = shops.filter(shop => {
    const notAvailableForOrderAhead =
      activeFilters.includes(resultFilterTypes.orderAhead) &&
      !shop.features.availableForOrderAheadCollection
    const notVeggiePret =
      activeFilters.includes(resultFilterTypes.veggiePret) &&
      shop.features.storeType !== storeTypes.veggiePret
    if (notAvailableForOrderAhead || notVeggiePret) {
      return false
    }
    return true
  })

  const errorMessageId = getErrorMessageId({
    isSearching,
    hasSearched,
    hasApiThrownError,
    shopsLength: shops.length,
    filteredShopsLength: filteredShops.length,
    activeFilters
  })

  const shouldHideMapAndSelector = errorMessageId

  return (
    <>
      <ShopFinderPageHeading>{storeHeadingtext}</ShopFinderPageHeading>
      <ShopSearchGrid areas={shopFinderGridAreas}>
        <SearchGridArea name='search'>
          <SearchContainer>
            <SearchFormHeading level='h2' styleOverride='headingSm'>
              <Translation id='storefinder.search.location.label' />
            </SearchFormHeading>
            <SearchForm onSubmit={shopSearch}>
              <LocationInput
                id='shop-search-field'
                name='address'
                label={
                  translate('storefinder.search.location.label', locale)?.value
                }
                placeholder={
                  translate('storefinder.search.location.placeholder', locale)
                    ?.value
                }
                autoComplete='off'
                defaultValue={address}
                hideLabel
                data-testid='store-search-input'
              />
              <SearchButton
                type='submit'
                title={
                  translate('storefinder.search.button.title', locale)?.value
                }
                data-testid='store-search-button'
              >
                <SearchIcon colour={getColours().white} />
              </SearchButton>
            </SearchForm>
            <CurrentLocationButton
              styleType='tertiary'
              icon={<Location />}
              onClick={searchByCurrentLocation}
              compact
            >
              <Translation id='storefinder.search.usecurrentlocation' />
            </CurrentLocationButton>
            {inPagePromo}
            {shops.length !== 0 && showFilterMenu && (
              <ShopFinderResultsFilter
                activeFilters={activeFilters}
                onFilterChange={setActiveFilters}
                displayClickAndCollect={displayClickAndCollect}
              />
            )}
          </SearchContainer>
        </SearchGridArea>
        <MessageGridArea name='search-result-message'>
          <AriaLive role='alert'>
            {errorMessageId && (
              <ErrorWrapper>
                {!hasApiThrownError && (
                  <ErrorHeading level='h3' styleOverride='headingSmLight'>
                    <Translation id='storefinder.search.noShopsFound' />
                  </ErrorHeading>
                )}
                <ErrorDescription>
                  <Translation id={errorMessageId} />
                </ErrorDescription>
              </ErrorWrapper>
            )}
          </AriaLive>
        </MessageGridArea>
        <ViewSelectionGridArea
          name='view-selection'
          hidden={shouldHideMapAndSelector}
        >
          <ViewSelector
            active={view === 'map'}
            className='view-selector-map'
            mapTab
            onClick={() => setView('map')}
            type='button'
          >
            <Translation id='storefinder.search.results.map.label' />
          </ViewSelector>
          <ViewSelector
            type='button'
            active={view === 'list'}
            onClick={() => setView('list')}
            aria-label={
              translate('storefinder.search.results.list.label', locale)?.value
            }
          >
            <Translation id='storefinder.search.results.list.label' />
          </ViewSelector>
        </ViewSelectionGridArea>
        <ListGridArea
          name='list'
          active={view === 'list'}
          hidden={shouldHideMapAndSelector}
        >
          <AriaLive role='status' className='sr-only'>
            <Translation
              id='storefinder.search.shopsFoundCount'
              tokens={{ count: filteredShops.length }}
            />
          </AriaLive>
          {!hasSearched ? (
            <ShopFinderRegionList
              regions={localeRegions}
              onSelectRegion={searchByLatLong}
            />
          ) : (
            <ShopList aria-label={storeHeadingtext}>
              {filteredShops.map((shop, index) => (
                <ShopFinderShopListItem
                  key={shop.id}
                  index={index + 1}
                  shop={shop}
                  loading={!!(updating && activeItem === shop.id)}
                  onClickStartOrder={selectShopForOrderAhead}
                  onClickInfo={showShopModal}
                  displayClickAndCollect={displayClickAndCollect}
                  showTextOnPin
                />
              ))}
            </ShopList>
          )}
          {!hasSearched && !localeRegions.length && !filteredShops.length && (
            <ListViewDescription data-testid='list-view-description'>
              <Translation id='storefinder.list-view.no-results' />
            </ListViewDescription>
          )}
        </ListGridArea>
        <MapGridArea
          name='map'
          active={view === 'map'}
          ref={mapContainerRef}
          hidden={shouldHideMapAndSelector}
        >
          {!isSearching && (
            <ShopFinderMap
              apiKey={googleMapsJavaScriptApiKey}
              center={getCenter()}
              defaultFitBounds={defaultFitBounds}
              mapDimension={mapDimension}
              onClick={hideMarkerModal}
              places={
                filteredShops.length
                  ? filteredShops.map(shop => shop.location)
                  : shops.map(shop => shop.location)
              }
              showPOI={showPOI}
            >
              {filteredShops
                .map((shop, index) => (
                  <ShopFinderMapMarker
                    onClick={e => {
                      showShopMarkerPopup(index + 1)(shop)
                      e.stopPropagation()
                    }}
                    key={shop.id}
                    lat={shop.location.lat}
                    lng={shop.location.lng}
                    isTactical={false}
                    isClosed={!shop.isCurrentlyOpen}
                    isMapPin
                    showInfoModal={
                      shopIndexForMarkerModal === index + 1 && isMarkerModalOpen
                    }
                    isVeggiePret={
                      shop.features.storeType === storeTypes.veggiePret
                    }
                    title={
                      translate('storefinder.store.pin.description', locale, {
                        index
                      })?.value
                    }
                    infoModal={
                      shopForMarkerModal && (
                        <ShopFinderMapMarkerModal
                          displayClickAndCollect={displayClickAndCollect}
                          onClickInfo={showShopModal}
                          onClickStartOrder={selectShopForOrderAhead}
                          loading={!!(updating && activeItem === shop.id)}
                          index={shopIndexForMarkerModal}
                          shop={shopForMarkerModal}
                          onClose={hideMarkerModal}
                        />
                      )
                    }
                  />
                ))
                .reverse()}
            </ShopFinderMap>
          )}
        </MapGridArea>
      </ShopSearchGrid>
      {isShopModalOpen && (
        <Modal size='standard' open={isShopModalOpen} onClose={hideShopModal}>
          <ShopFinderShopDetails
            shop={shopForShopModal}
            displayClickAndCollect={displayClickAndCollect}
            loading={!!(updating && activeItem === shopForShopModal.id)}
            onClickStartOrder={selectShopForOrderAhead}
          />
        </Modal>
      )}
    </>
  )
}

ShopFinder.propTypes = {
  displayClickAndCollect: PropTypes.bool,
  displayClickAndCollectShopsOnly: PropTypes.bool,
  showFilterMenu: PropTypes.bool,
  defaultCenter: location.isRequired,
  defaultFitBounds: PropTypes.shape({
    ne: location,
    sw: location
  }).isRequired,
  defaultFilters: PropTypes.arrayOf(PropTypes.string),
  inPagePromo: PropTypes.node,
  googleMapsJavaScriptApiKey: PropTypes.string.isRequired,
  localeRegions: PropTypes.arrayOf(shopFinderRegion),
  showPOI: PropTypes.bool
}

ShopFinder.defaultProps = {
  displayClickAndCollectShopsOnly: false,
  showFilterMenu: true,
  displayClickAndCollect: false,
  defaultFilters: defaultFilterValues,
  localeRegions: [],
  showPOI: false
}

export default ShopFinder
