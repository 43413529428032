import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { storeProps } from '@proptypes'
import { Heading } from '@pretamanger/component-library'
import { storeTypes } from '#constants'
import Translation, { translate } from '#src/common/components/translation'
import { transformAddressToString } from '#src/common/lib/format-address'
import { getTodayHours } from '#src/shop-finder/util/dates'
import ShopFinderStartOrderButton from '#src/shop-finder/components/shop-finder-start-order-button'

import {
  Wrapper,
  ShopAddress,
  ShopOpenWrapper,
  ShopOpenTime,
  OrderAheadContainer,
  OrderAheadWrapper,
  OrderAheadClock,
  OrderAheadTime,
  VeggiePretBadge,
  ShopInfoButton
} from './styles'

const ShopFinderShopInformation = ({
  shop,
  onClickInfo,
  onClickStartOrder,
  isModal,
  loading,
  displayClickAndCollect,
  index
}) => {
  const { locale } = useRouter()
  const address = transformAddressToString(shop.address)
  const openingHoursToday = getTodayHours(shop.tradingHours, locale)
  const isVeggiePret = shop.features.storeType === storeTypes.veggiePret
  const isCurrentlyOpen = shop.isCurrentlyOpen
  const veggiePretLabel = translate(
    'storefinder.store.veggiePret',
    locale
  )?.value

  return (
    <Wrapper isModal={isModal}>
      <div className='flex items-center'>
        <Heading
          className='text-grey-700'
          level='h4'
          styleOverride='heading2XsLight'
        >
          {shop.name}
        </Heading>
        {isVeggiePret ? (
          <VeggiePretBadge aria-label={veggiePretLabel} colour='green'>
            v
          </VeggiePretBadge>
        ) : null}
      </div>
      <ShopAddress>{address}</ShopAddress>
      <ShopOpenWrapper className='mb-2' closed={!isCurrentlyOpen}>
        {isCurrentlyOpen ? (
          <Translation id='storefinder.store.openToday' />
        ) : (
          <Translation id='storefinder.store.closedToday' />
        )}
        {isCurrentlyOpen && (
          <ShopOpenTime>
            :{' '}
            <span className='ml-1 text-sm leading-sm'>{openingHoursToday}</span>
          </ShopOpenTime>
        )}
      </ShopOpenWrapper>
      {displayClickAndCollect && (
        <OrderAheadContainer>
          <OrderAheadWrapper isModal={isModal}>
            <OrderAheadClock colour='grey-700' />
            <OrderAheadTime>{shop.orderAheadNextAvailable}</OrderAheadTime>
          </OrderAheadWrapper>
          {shop.isCurrentlyAvailableForOA && (
            <ShopFinderStartOrderButton
              classes='overflow-hidden sm:overflow-auto h-0 sm:h-auto'
              loading={loading}
              onClick={onClickStartOrder}
              index={index}
            />
          )}
        </OrderAheadContainer>
      )}
      <ShopInfoButton onClick={onClickInfo} styleType='tertiary'>
        <Translation id='storefinder.store.shopInfo' />
      </ShopInfoButton>
      {displayClickAndCollect && shop.isCurrentlyAvailableForOA && (
        <ShopFinderStartOrderButton
          classes='sm:hidden'
          loading={loading}
          onClick={onClickStartOrder}
          index={index}
        />
      )}
    </Wrapper>
  )
}

ShopFinderShopInformation.propTypes = {
  onClickInfo: PropTypes.func.isRequired,
  onClickStartOrder: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
  loading: PropTypes.bool,
  shop: storeProps.isRequired,
  displayClickAndCollect: PropTypes.bool,
  index: PropTypes.number
}

ShopFinderShopInformation.defaultProps = {
  loading: false,
  isModal: false,
  displayClickAndCollect: false
}

export default ShopFinderShopInformation
