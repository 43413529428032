import PropTypes from 'prop-types'
import { Wrapper } from './styles'
import { getColours } from '@pretamanger/component-library'

const colours = getColours()

const renderMarker = isClosed => {
  return (
    <>
      <rect x='7' y='8' width='15' height='14' fill='#F8F7F7' />
      <path
        d='M14.5 0C6.49534 0 0 6.73509 0 15.0352C0 23.3431 14.5 44 14.5 44C14.5 44 29 23.3353 29 15.0352C29 6.73509 22.5122 0 14.5 0ZM18.3446 20.2286L14.5 17.6592L10.6554 20.2053L11.9995 15.7749L8.50777 13.2055H12.9006L14.5075 8.30791L16.137 13.2288H20.5072L17.0155 15.7983L18.3446 20.2286Z'
        fill={isClosed ? colours['grey-300'] : colours['pretRed-700']}
      />
    </>
  )
}

const renderVeggieMarker = isClosed => {
  return (
    <>
      <path
        d='M14.5 0C6.49534 0 0 6.73509 0 15.0352C0 23.3431 14.5 44 14.5 44C14.5 44 29 23.3353 29 15.0352C29 6.73509 22.5122 0 14.5 0ZM18.3446 20.2286L14.5 17.6592L10.6554 20.2053L11.9995 15.7749L8.50777 13.2055H12.9006L14.5075 8.30791L16.137 13.2288H20.5072L17.0155 15.7983L18.3446 20.2286Z'
        fill={isClosed ? colours['grey-300'] : colours.veggiePretGreen}
      />
      <path
        d='M11.9467 8.00016L17.28 7.3335L24.6133 11.3335V17.3335L19.9467 29.3335L5.94666 24.0002L3.94666 17.3335L7.94666 9.3335L11.9467 8.00016Z'
        fill={isClosed ? colours['grey-300'] : colours.veggiePretGreen}
      />
      <circle cx='14.5' cy='14.5' r='10' stroke='white' />
      <path
        d='M15.136 18H13.9L11.644 11.7H12.94L14.068 15.12C14.172 15.424 14.264 15.716 14.344 15.996C14.432 16.268 14.496 16.492 14.536 16.668C14.576 16.492 14.632 16.268 14.704 15.996C14.784 15.716 14.872 15.424 14.968 15.12L16.048 11.7H17.356L15.136 18Z'
        fill='white'
      />
    </>
  )
}

const ShopFinderMapMarker = ({
  title,
  onClick,
  showInfoModal,
  infoModal,
  isClosed,
  isVeggiePret,
  isMapPin,
  className
}) => {
  const pinHeight = 48
  const pinWidth = 32
  return (
    <Wrapper
      isMapPin={isMapPin}
      pinHeight={pinHeight}
      pinWidth={pinWidth}
      className={className}
    >
      <svg
        onClick={onClick}
        role='img'
        width={pinWidth}
        height={pinHeight}
        viewBox={`0 0 ${pinWidth} ${pinHeight}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden
      >
        <title>{title}</title>
        {isVeggiePret ? renderVeggieMarker(isClosed) : renderMarker(isClosed)}
      </svg>
      {showInfoModal && infoModal}
    </Wrapper>
  )
}

ShopFinderMapMarker.propTypes = {
  className: PropTypes.string,
  infoModal: PropTypes.node,
  isMapPin: PropTypes.bool,
  isVeggiePret: PropTypes.bool,
  isClosed: PropTypes.bool,
  onClick: PropTypes.func,
  showInfoModal: PropTypes.bool,
  title: PropTypes.string
}

ShopFinderMapMarker.defaultProps = {
  infoModal: null,
  isMapPin: false,
  isVeggiePret: false,
  isClosed: false,
  onClick: () => {},
  showInfoModal: false,
  title: ''
}

export default ShopFinderMapMarker
