import { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Chevron } from '@pretamanger/component-library'
import Translation, { translate } from '#src/common/components/translation'
import {
  FilterButton,
  ClearButton,
  ApplyButton,
  FilterCheckbox
} from './styles'
import { storeTypes } from '#constants'

export const resultFilterTypes = {
  orderAhead: storeTypes.orderAhead,
  veggiePret: storeTypes.veggiePret
}

export const defaultFilterValues = []

const ShopFinderResultsFilter = ({
  activeFilters = defaultFilterValues,
  defaultFilterOpen,
  onFilterChange,
  displayClickAndCollect
}) => {
  const { locale } = useRouter()
  const [filterOpen, setFilterOpen] = useState(defaultFilterOpen)
  const [filterValues, setFilterValues] = useState(activeFilters)

  const toggleOpen = () => setFilterOpen(!filterOpen)

  const labelForVeggiePretShops = translate(
    'storefinder.search.veggiePretShops',
    locale
  )?.value

  const handleFilterChange = ({ target: { name } }) => {
    if (filterValues.includes(name)) {
      return setFilterValues(filterValues.filter(v => v !== name))
    }
    return setFilterValues([...filterValues, name])
  }

  const handleApplyFilters = useCallback(() => {
    onFilterChange(filterValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues])

  const handleClearFilters = useCallback(() => {
    if (filterValues.length !== activeFilters.length) {
      setFilterValues(defaultFilterValues)
    }
    onFilterChange(defaultFilterValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, activeFilters])

  useEffect(() => {
    setFilterValues(activeFilters)
  }, [activeFilters])

  return (
    <div className='border-t border-grey-200 py-4 px-1'>
      <div className='flex justify-end items-center'>
        <FilterButton
          aria-controls='shop-finder-filter'
          aria-expanded={filterOpen}
          type='button'
          onClick={toggleOpen}
        >
          <Chevron
            aria-hidden='true'
            type={filterOpen ? 'up' : 'down'}
            className='mr-1'
          />
          <Translation id='storefinder.search.filterResults' />{' '}
          {activeFilters.length
            ? translate('storefinder.search.filterCount', locale, {
                count: activeFilters.length
              })?.value
            : null}
        </FilterButton>
      </div>
      {filterOpen && (
        <div id='shop-finder-filter'>
          <p className='mb-2 text-sm'>
            <Translation id='storefinder.search.showMe' />:
          </p>
          <div className='grid grid-cols-2'>
            {displayClickAndCollect && (
              <FilterCheckbox
                id={resultFilterTypes.orderAhead}
                name={resultFilterTypes.orderAhead}
                label={
                  translate('storefinder.search.orderAheadShops', locale)?.value
                }
                onChange={handleFilterChange}
                defaultChecked={filterValues.includes(
                  resultFilterTypes.orderAhead
                )}
                key={`${resultFilterTypes.orderAhead}-${filterValues.length}`}
              />
            )}
            {labelForVeggiePretShops && (
              <FilterCheckbox
                id={resultFilterTypes.veggiePret}
                name={resultFilterTypes.veggiePret}
                label={labelForVeggiePretShops}
                onChange={handleFilterChange}
                defaultChecked={filterValues.includes(
                  resultFilterTypes.veggiePret
                )}
                key={`${resultFilterTypes.veggiePret}-${filterValues.length}`}
              />
            )}
          </div>
          <div className='mt-3 flex'>
            <ApplyButton type='button' onClick={handleApplyFilters}>
              <Translation id='storefinder.search.applyFilter' />
            </ApplyButton>
            <ClearButton type='button' onClick={handleClearFilters}>
              <Translation id='storefinder.search.clearAll' />
            </ClearButton>
          </div>
        </div>
      )}
    </div>
  )
}

ShopFinderResultsFilter.propTypes = {
  defaultFilterOpen: PropTypes.bool,
  displayClickAndCollect: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  activeFilters: PropTypes.arrayOf(PropTypes.string).isRequired
}

ShopFinderResultsFilter.defaultProps = {
  defaultFilterOpen: false,
  displayClickAndCollect: false
}

export default ShopFinderResultsFilter
