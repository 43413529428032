import styled from '@emotion/styled'
import { Button } from '@pretamanger/component-library'
import tw from 'twin.macro'

export const Container = styled(Button)`
  ${tw`p-1 px-2 h-8 whitespace-nowrap float-right mt-2 rounded`}
  svg {
    ${tw`ml-1`}
  }
  div:first-of-type {
    ${tw`ml-0 mr-0`}
  }
  span:last-of-type {
    ${tw`mr-0`}
  }
  &:hover,
  &:active,
  &:focus {
    svg path {
      stroke: white !important;
    }
  }
`

export const ButtonLabel = styled('div')`
  ${tw`text-sm mx-1 whitespace-nowrap`}
  ${props => (props.isButtonDisabled ? tw`text-grey-200` : tw`text-current`)}
`
