import { weekdays } from '#constants'
import { translate } from '#src/common/components/translation'
import { getDate, getDay } from '#src/common/lib/date-utils'

const formatStoreDayTradingHours = tradingHours =>
  `${tradingHours[0]} - ${tradingHours[1]}`

export const formatStoreWeekTradingHours = (storeTradingHours, locale) => {
  return storeTradingHours.map((dayTradingHours, index) => {
    return {
      day: translate(
        `storefinder.storetimings.weekday.${weekdays[index]}`,
        locale
      )?.value,
      tradingHour:
        dayTradingHours[0] !== dayTradingHours[1]
          ? formatStoreDayTradingHours(dayTradingHours, locale)
          : translate('storefinder.store.closed', locale)?.value
    }
  })
}

export const getTodayHours = (weeklyTradingHours, locale) => {
  const time = getDate()
  const today = getDay(time)
  const dayHours = weeklyTradingHours[today]
  if (dayHours[0] === dayHours[1]) return null
  return formatStoreDayTradingHours(dayHours, locale)
}

export const sortTradingHoursMondayToSunday = ([sun, ...monToSat]) => [
  ...monToSat,
  sun
]
