import PropTypes from 'prop-types'
import { MapMarkerContainer } from './styles'
import { getColours } from '@pretamanger/component-library'

const colours = getColours()

const renderMarker = () => {
  return (
    <path
      d='M16 0C7.16727 0 0 7.34737 0 16.4021C0 25.4652 16 48 16 48C16 48 32 25.4567 32 16.4021C32 7.34737 24.841 0 16 0ZM20.2424 22.0676L16 19.2646L11.7576 22.0421L13.2408 17.209L9.38788 22.0676Z'
      fill={colours['pretRed-700']}
    />
  )
}

const renderVeggieMarker = () => {
  return (
    <>
      <path
        d='M0 16.4021C0 7.34737 7.16727 0 16 0C24.841 0 32 7.34737 32 16.4021C32 25.4567 16 48 16 48C16 48 0 25.4652 0 16.4021Z'
        fill={colours.veggiePretGreen}
      />
      <path
        d='M4 16.3267C4 9.49848 9.39894 4 16 4C22.6075 4 28 9.49814 28 16.3267C28 17.9395 27.2679 20.297 26.0544 23.0369C24.8578 25.7385 23.2544 28.6804 21.6391 31.4134C20.0258 34.143 18.4109 36.6468 17.1986 38.4692C16.7309 39.1723 16.3236 39.7734 15.9999 40.2461C15.6762 39.7736 15.2689 39.1728 14.8013 38.4701C13.589 36.6482 11.9742 34.1451 10.3608 31.4159C8.74557 28.6834 7.14215 25.7418 5.9456 23.0398C4.73215 20.2997 4 17.9413 4 16.3267Z'
        fill={colours.veggiePretGreen}
        stroke={colours.white}
        strokeWidth='2'
      />
    </>
  )
}

export const MapMarker = ({
  title,
  onClick,
  children,
  showTextOnPin,
  showInfoModal,
  infoModal,
  isVeggiePret,
  isMapPin
}) => {
  const pinHeight = 48
  const pinWidth = 32
  return (
    <MapMarkerContainer
      isMapPin={isMapPin}
      pinHeight={pinHeight}
      pinWidth={pinWidth}
    >
      <svg
        onClick={onClick}
        role='img'
        width={pinWidth}
        height={pinHeight}
        viewBox={`0 0 ${pinWidth} ${pinHeight}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden
      >
        <title>{title}</title>
        {isVeggiePret ? renderVeggieMarker() : renderMarker()}
        {showTextOnPin && (
          <text x='50%' y='50%' textAnchor='middle' fill='white'>
            {children}
          </text>
        )}
      </svg>
      {showInfoModal && infoModal}
    </MapMarkerContainer>
  )
}

MapMarker.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  infoModal: PropTypes.node,
  isMapPin: PropTypes.bool,
  isVeggiePret: PropTypes.bool,
  onClick: PropTypes.func,
  showInfoModal: PropTypes.bool,
  showTextOnPin: PropTypes.bool,
  title: PropTypes.string
}

MapMarker.defaultProps = {
  isVeggiePret: false,
  onClick: () => {},
  showInfoModal: false,
  showTextOnPin: true
}
