import styled from '@emotion/styled'
import { css } from '@emotion/react'
import tw from 'twin.macro'
import { Button, Cross, getScreenSizes } from '@pretamanger/component-library'
import { MapMarker } from '../map-marker'

export const StoreListItemContainer = styled('li')`
  ${tw`py-2 border-grey-200 border-b last:border-b-0`}
`

export const StoreListItemContent = styled('div')<{ disabled: boolean }>`
  ${tw`grid p-2 hover:bg-grey-50 gap-8 outline-none focus-within:bg-grey-50`}
  grid-template-columns: 2rem 1fr;
  &:last-of-type {
    border: 0;
  }
  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`gap-4`}
    grid-template-columns: 1.5rem 1fr;
  }
  ${props =>
    props.disabled ? tw`opacity-25 pointer-events-none` : tw`cursor-pointer`}
`

export const StyledMapMarker = styled(MapMarker)`
  ${tw`h-10 w-6 md:h-12 md:w-8`}
  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`row-start-1 row-end-1`}
  }
`

export const ShopInformationLeftContainer = styled('div')`
  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`row-start-1 row-end-1 col-start-2 col-end-4`}
  }
`

const veggiePretStore = css`
  ${tw`text-veggiePretGreen`}
`

const pretStore = css`
  ${tw`text-pretRed-700`}
`

export const StoreInformationButton = styled(Button)`
  ${tw`text-base text-pretRed-700 mt-4 outline-none focus:shadow-outline`}
  svg {
    ${tw`hidden`}
  }
  ${props => (props.isVeggiePret ? veggiePretStore : pretStore)}
`

export const ShopInformationButton = styled(Button)`
  &&& {
    ${tw`justify-start p-0 mt-4 border-0`}
    ${props => (props.isVeggiePret ? veggiePretStore : pretStore)}
  
  span {
      ${props => (props.isVeggiePret ? veggiePretStore : pretStore)}
    }
    svg {
      ${tw`hidden`}
    }
  }
`

export const ShopOpenTime = styled('p')`
  ${tw`text-sm leading-sm text-grey-700`}
`

export const ShopAddress = styled('p')`
  ${tw`text-sm leading-sm text-grey-500`}
`

export const WheelchairAccess = styled('p')`
  ${tw`text-sm text-grey-700 flex items-center content-center mt-2`}
  svg {
    ${tw`mr-1`}
  }
`

export const ShopInformationContainer = styled('div')<{ isModal: boolean }>`
  ${tw`flex flex-col items-start md:flex-row md:items-center justify-between`}
  ${props => props.isModal && tw`items-end`}
`

export const ShopInformationRightContainer = styled('div')<{
  isModal: boolean
}>`
  ${tw`text-left pt-2 md:pt-0 md:text-right content-start md:self-end`}
  ${props => (props.isModal ? 'min-width: 85px' : 'min-width: 105px')}
  > div {
    ${tw`content-start`}
  }
`
const modalWidth = 300
const modalSmWidth = 250

export const ShopMarkerModalWrapper = styled('div')`
  ${tw`bg-white absolute z-10 shadow`}
  width: ${modalWidth}px;
  left: -${modalWidth / 2}px;
  bottom: 70px;

  @media (max-width: ${getScreenSizes().sm}px) {
    width: ${modalSmWidth}px;
    left: -${modalSmWidth / 2}px;
  }

  li {
    ${tw`list-none`}
  }
`

export const ShopMarkerModalCloseButton = styled(Button)`
  &&& {
    ${tw`p-2 flex float-right bg-white hover:bg-transparent absolute z-30`}
    top: 5px;
    right: 5px;

    span {
      ${tw`ml-1`}
    }
  }
`

export const ShopMarkerModalCross = styled(Cross)`
  ${tw`inline px-1 cursor-pointer`}
`

export const ShopMarkerDetails = styled('div')`
  ${tw`p-6 relative z-20 bg-white`}
  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`p-3`}
  }
`

export const ShopMarkerModalDownPointer = styled('div')`
  ${tw`bg-white absolute z-10 shadow transform rotate-45`}
  bottom: -10px;
  left: ${modalWidth / 2}px;
  width: 20px;
  height: 20px;

  @media (max-width: ${getScreenSizes().sm}px) {
    left: ${modalSmWidth / 2}px;
  }
`

export const SelectShopButton = styled(Button)`
  ${tw`relative text-sm no-underline row-start-1 row-end-1 col-start-3 col-end-3 disabled:opacity-50 disabled:pointer-events-none`}
  ${props => props.isModal && tw`hidden`}

  @media (max-width: ${getScreenSizes().sm}px) {
    ${tw`self-end row-start-2 row-end-2 col-start-3 col-end-3`}
  }
`
