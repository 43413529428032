import { useCallback, useState } from 'react'
import { exitFullScreen } from '#src/shop-finder/util/ui'

const useShopModal = () => {
  const [shopForShopModal, setShopForShopModal] = useState({})
  const [isShopModalOpen, setShowShopModal] = useState(false)

  const showShopModal = useCallback(shop => {
    exitFullScreen()
    setShopForShopModal(shop)
    setShowShopModal(true)
  }, [])

  const hideShopModal = () => {
    setShowShopModal(false)
    setShopForShopModal({})
  }

  return {
    isShopModalOpen,
    shopForShopModal,
    showShopModal,
    hideShopModal
  }
}

export default useShopModal
