import React from 'react'
import { useRouter } from 'next/router'
import {
  Phone,
  WheelChair,
  Wifi,
  Bag,
  Seat
} from '@pretamanger/component-library'
import {
  ShopDetailsContent,
  ShopDetailsCol,
  ShopAddress,
  ShopName,
  Section,
  SectionTitle,
  ShopTradingHoursList,
  ShopTitleContainer,
  ShopFeature,
  SectionContent,
  ShopFeatureIconContainer,
  ShopContactInfoButton
} from './styles'
import { translate } from '../../../common/components/translation'
import { storeProps } from '@proptypes'
import { weekdays, storeTypes, shopSeatingNoSeats } from '#constants'
import { transformAddressToString } from '#src/common/lib/format-address'
import { sortTradingHoursMondayToSunday } from '#src/shop-finder/util/dates'

const formatTradingHours = (tradingHours, locale) => {
  return tradingHours.map((tradingHour, index) => {
    return {
      day: translate(
        `storefinder.storetimings.weekday.${weekdays[index]}`,
        locale
      )?.value,
      tradingHour:
        tradingHour[0] !== tradingHour[1]
          ? `${tradingHour[0]} - ${tradingHour[1]}`
          : translate('storefinder.store.closed', locale)?.value
    }
  })
}

const ShopDetails = ({ shop }) => {
  const { locale } = useRouter()
  const address = transformAddressToString(shop.address)
  const tradingHours = formatTradingHours(shop.tradingHours, locale)
  const tradingHoursSorted = sortTradingHoursMondayToSunday(tradingHours)
  const isVeggiePret = shop.features.storeType === storeTypes.veggiePret
  return (
    <div>
      <ShopTitleContainer>
        <ShopName level='h2' styleOverride='headingSm'>
          {shop.name}
        </ShopName>
        <ShopAddress>{address}</ShopAddress>
      </ShopTitleContainer>
      <ShopDetailsContent>
        <ShopDetailsCol>
          <Section>
            <SectionTitle>
              {
                translate('storefinder.storetimings.tradingHours', locale)
                  ?.value
              }
            </SectionTitle>
            <SectionContent>
              <ShopTradingHoursList>
                {tradingHoursSorted.map(tradingHour => (
                  <li key={tradingHour.day}>
                    <span>{tradingHour.day}</span>
                    <span>{tradingHour.tradingHour}</span>
                  </li>
                ))}
              </ShopTradingHoursList>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>
              {translate('storefinder.store.contactInformation', locale)?.value}
            </SectionTitle>
            <SectionContent>
              <ShopContactInfoButton
                isVeggiePret={isVeggiePret}
                styleType='tertiary'
                href={`tel:${shop.contact.phone}`}
                icon={
                  <Phone
                    colour={isVeggiePret ? 'veggiePretGreen' : 'pretRed-700'}
                  />
                }
              >
                {' '}
                {shop.contact.phone}
              </ShopContactInfoButton>
            </SectionContent>
          </Section>
        </ShopDetailsCol>
        <ShopDetailsCol>
          {(shop.features.wheelchairAccess || shop.features.wifi) && (
            <Section>
              <SectionTitle>
                {translate('storefinder.store.facilities', locale)?.value}
              </SectionTitle>
              <SectionContent>
                {shop.features.wheelchairAccess && (
                  <ShopFeature>
                    <ShopFeatureIconContainer>
                      <WheelChair colour='grey-900' />
                    </ShopFeatureIconContainer>
                    <span>
                      {
                        translate('storefinder.store.wheelchairaccess', locale)
                          ?.value
                      }
                    </span>
                  </ShopFeature>
                )}
                {shop.features.wifi && (
                  <ShopFeature>
                    <ShopFeatureIconContainer>
                      <Wifi colour='grey-900' />
                    </ShopFeatureIconContainer>
                    <span>
                      {translate('storefinder.store.wifiaccess', locale)?.value}
                    </span>
                  </ShopFeature>
                )}
                {shop.features.seating !== shopSeatingNoSeats && (
                  <ShopFeature>
                    <ShopFeatureIconContainer>
                      <Seat colour='grey-900' />
                    </ShopFeatureIconContainer>
                    <span>
                      {translate('storefinder.store.seating', locale)?.value}
                    </span>
                  </ShopFeature>
                )}
              </SectionContent>
            </Section>
          )}
          {shop.features.availableForOrderAheadCollection && (
            <Section>
              <SectionTitle>
                {translate('storefinder.store.services', locale)?.value}
              </SectionTitle>
              <SectionContent>
                <ShopFeature>
                  <ShopFeatureIconContainer>
                    <Bag colour='grey-900' />
                  </ShopFeatureIconContainer>
                  <span>
                    {translate('storefinder.store.orderahead', locale)?.value}
                  </span>
                </ShopFeature>
              </SectionContent>
            </Section>
          )}
        </ShopDetailsCol>
      </ShopDetailsContent>
    </div>
  )
}

ShopDetails.propTypes = {
  shop: storeProps
}

export default ShopDetails
