import qs from 'qs'
import geocodeByCountry from '../../../../geocode-by-country'
import platformClient from '#lib/platform-client'

export const storeSearchByAddress = async ({ address, market }) => {
  const query = qs.stringify({ address, market })
  const response = await platformClient(`/shops/by-address?${query}`)
  return response
}

export const storeSearchByLocation = async ({ lat, lng }) => {
  const query = qs.stringify({ lat, lng })
  const response = await platformClient(`/shops/by-location?${query}`)
  return response
}

export const getGeocodeByLocale = locale => {
  const geocode = geocodeByCountry[locale].geometry
  const center = geocode.location
  const fitBounds = {
    ne: geocode.viewport.northeast,
    sw: geocode.viewport.southwest
  }
  return {
    center,
    fitBounds
  }
}
