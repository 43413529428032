import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import {
  ActivitySpinner,
  getColours,
  Heading,
  Location,
  Modal,
  Search as SearchIcon
} from '@pretamanger/component-library'

import Translation, { translate } from '#src/common/components/translation'
import { location, storeProps } from '#proptypes/index'
import { storeTypes } from '#constants'
import { useResize } from '#hooks'

import StoreListItem from '../store-list-item'
import { MapMarker } from '../map-marker'
import ShopMarkerModal from '../store-list-item/shop-marker-modal'
import ShopDetails from '../shop-details'

import useShopSearch from './use-shop-search'
import useShopModal from './use-shop-modal'
import useShopMarkerModal from './use-shop-marker-modal'
import {
  CurrentLocationButton,
  ListGridArea,
  LocationInput,
  MapGridArea,
  MessageGridArea,
  SearchButton,
  SearchContainer,
  SearchForm,
  SearchGridArea,
  SelectShopButtonContainer,
  ShopFinderPageHeading,
  ShopList,
  ShopListDescription,
  ShopMap,
  ShopSearchGrid,
  ViewSelectionGridArea,
  ViewSelector
} from './styles'
import { areas } from './shop-finder-grid'
import BackButton from '#src/common/app/components/navigation/back'
import { useStoreSelection } from './use-store-selection'
import {
  SelectShopButton,
  ShopInformationButton
} from '#src/store-finder/components/store-list-item/styles'

const ShopFinderContainer = ({
  defaultCenter,
  defaultFitBounds,
  defaultShops,
  inPagePromo,
  googleMapsJavaScriptApiKey
}) => {
  const { locale } = useRouter()
  const {
    search,
    searchByLatLong,
    hasResultsByLocation,
    isSearching,
    hasSearched,
    hasApiThrownError,
    address,
    shops
  } = useShopSearch(defaultShops)

  const { isShopModalOpen, shopForShopModal, showShopModal, hideShopModal } =
    useShopModal()

  const {
    isMarkerModalOpen,
    shopIndexForMarkerModal,
    shopForMarkerModal,
    showMarkerModal,
    hideMarkerModal
  } = useShopMarkerModal()

  const { activeItem, updating, selectShopForOrderAhead, isAuthLoading } =
    useStoreSelection()

  const [view, setView] = useState('list')
  const [mapDimension, setMapDimension] = useState(null)
  const mapContainerRef = useRef()
  const hasDefaultShops = defaultShops.length > 0

  const setMapWidthAndHeight = () => {
    mapContainerRef.current &&
      setMapDimension({
        width: mapContainerRef.current.offsetWidth,
        height: mapContainerRef.current.offsetHeight
      })
  }

  useEffect(() => {
    setMapWidthAndHeight()
  }, [shops, view])

  useEffect(() => {
    const contentContainer = document.querySelector('main#main-content')
    contentContainer.classList.add('shop-search-content')
    return () => contentContainer.classList.remove('shop-search-content')
  }, [])

  useResize(() => {
    setMapWidthAndHeight()
  })

  const getCenter = useCallback(() => {
    if (shopForMarkerModal) {
      return shopForMarkerModal.location
    }
    if (!shops.length) {
      return defaultCenter
    }

    if (shops.length > 0) {
      return shops[0].location
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopForMarkerModal, shops])

  const showShopMarkerPopup = newShopIndex => shop => {
    showMarkerModal(newShopIndex, shop)
  }

  const shopSearch = e => {
    hideShopModal()
    hideMarkerModal()
    search(e)
  }

  const storeHeadingtext = hasDefaultShops
    ? translate('storefinder.tactical.page.heading', locale)?.value
    : translate('storefinder.heading', locale)?.value

  return (
    <>
      <ShopFinderPageHeading>{storeHeadingtext}</ShopFinderPageHeading>
      <ShopSearchGrid areas={areas}>
        <SearchGridArea name='search'>
          {!hasDefaultShops && (
            <SearchContainer>
              <SearchForm onSubmit={shopSearch}>
                <LocationInput
                  id='shop-search-field'
                  name='address'
                  label={
                    translate('storefinder.search.location.label', locale)
                      ?.value
                  }
                  placeholder={
                    translate('storefinder.search.location.placeholder', locale)
                      ?.value
                  }
                  autoComplete='off'
                  defaultValue={address}
                />
                <SearchButton
                  type='submit'
                  title={
                    translate('storefinder.search.button.title', locale)?.value
                  }
                >
                  <SearchIcon colour={getColours().white} />
                </SearchButton>
              </SearchForm>
              <CurrentLocationButton
                styleType='tertiary'
                icon={<Location />}
                onClick={searchByLatLong}
                compact
              >
                {
                  translate('storefinder.search.usecurrentlocation', locale)
                    ?.value
                }
              </CurrentLocationButton>
              {inPagePromo}
            </SearchContainer>
          )}
        </SearchGridArea>
        <MessageGridArea name='search-result-message'>
          {hasDefaultShops ? (
            <>
              <BackButton />
              <Heading className='mb-6' level='h1' styleOverride='headingSm'>
                {translate('storefinder.tactical.page.heading', locale)?.value}
              </Heading>
            </>
          ) : (
            <>
              {!isSearching &&
                hasSearched &&
                !hasApiThrownError &&
                shops.length === 0 && (
                  <ShopListDescription>
                    {
                      translate(
                        'storefinder.search.no.results.description',
                        locale
                      )?.value
                    }
                  </ShopListDescription>
                )}
              {!isSearching && hasSearched && hasApiThrownError && (
                <ShopListDescription>
                  <Translation id='storefinder.search.apierror' />
                </ShopListDescription>
              )}
              <ShopListDescription>
                {hasResultsByLocation
                  ? translate(
                      'storefinder.search.results.descriptionbylocation',
                      locale,
                      { count: shops.length }
                    )?.value
                  : translate(
                      'storefinder.search.results.description',
                      locale,
                      {
                        count: shops.length,
                        address
                      }
                    )?.value}
              </ShopListDescription>
            </>
          )}
        </MessageGridArea>
        <ViewSelectionGridArea
          name='view-selection'
          hidden={shops.length === 0}
        >
          <ViewSelector
            type='button'
            active={view === 'list'}
            onClick={() => setView('list')}
          >
            {translate('storefinder.search.results.list.label', locale)?.value}
          </ViewSelector>
          <ViewSelector
            className='view-selector-map'
            type='button'
            active={view === 'map'}
            onClick={() => setView('map')}
          >
            {translate('storefinder.search.results.map.label', locale)?.value}
          </ViewSelector>
        </ViewSelectionGridArea>
        <ListGridArea
          name='list'
          active={view === 'list'}
          hidden={shops.length === 0}
        >
          <ShopList aria-label={storeHeadingtext}>
            {shops.map((shop, index) => (
              <StoreListItem
                key={shop.id}
                index={index + 1}
                store={shop}
                isTactical={hasDefaultShops}
                loading={!!(updating && activeItem === shop.id)}
                disabled={isAuthLoading}
                onClick={
                  hasDefaultShops ? selectShopForOrderAhead : showShopModal
                }
                showTextOnPin
              />
            ))}
          </ShopList>
        </ListGridArea>
        <MapGridArea
          name='map'
          active={view === 'map'}
          ref={mapContainerRef}
          hidden={shops.length === 0}
        >
          {!isSearching && (
            <ShopMap
              apiKey={googleMapsJavaScriptApiKey}
              center={getCenter()}
              defaultFitBounds={defaultFitBounds}
              mapDimension={mapDimension}
              onClick={hideMarkerModal}
              places={shops.map(shop => shop.location)}
            >
              {shops
                .map((shop, index) => (
                  <MapMarker
                    onClick={e => {
                      showShopMarkerPopup(index + 1)(shop)
                      e.stopPropagation()
                    }}
                    key={shop.id}
                    lat={shop.location.lat}
                    lng={shop.location.lng}
                    isTactical={false}
                    isMapPin
                    showInfoModal={
                      shopIndexForMarkerModal === index + 1 && isMarkerModalOpen
                    }
                    isVeggiePret={
                      shop.features.storeType === storeTypes.veggiePret
                    }
                    infoModal={
                      shopForMarkerModal && (
                        <ShopMarkerModal
                          actionButton={
                            <MarkerModalActionButton
                              action={
                                hasDefaultShops
                                  ? selectShopForOrderAhead
                                  : showShopModal
                              }
                              disabled={isAuthLoading}
                              loading={updating && activeItem === shop.id}
                              shop={shop}
                              showSelectShopButton={hasDefaultShops}
                            />
                          }
                          index={shopIndexForMarkerModal}
                          shop={shopForMarkerModal}
                          showDistanceInformation={!hasDefaultShops}
                          onClose={hideMarkerModal}
                        />
                      )
                    }
                  >
                    {index + 1}
                  </MapMarker>
                ))
                .reverse()}
            </ShopMap>
          )}
        </MapGridArea>
      </ShopSearchGrid>
      {isShopModalOpen && (
        <Modal open={isShopModalOpen} onClose={hideShopModal}>
          <ShopDetails shop={shopForShopModal} />
        </Modal>
      )}
    </>
  )
}

ShopFinderContainer.defaultProps = {
  defaultShops: [],
  inPagePromo: null
}

ShopFinderContainer.propTypes = {
  defaultCenter: location.isRequired,
  defaultFitBounds: PropTypes.shape({
    ne: location,
    sw: location
  }).isRequired,
  defaultShops: PropTypes.arrayOf(storeProps),
  inPagePromo: PropTypes.node,
  googleMapsJavaScriptApiKey: PropTypes.string.isRequired
}

export default ShopFinderContainer

const MarkerModalActionButton = ({
  action,
  loading,
  shop,
  disabled,
  showSelectShopButton
}) => {
  const onClickAction = e => {
    action(shop)
    e.stopPropagation()
  }

  return showSelectShopButton ? (
    <SelectShopButtonWithSpinner
      loading={loading}
      onClick={onClickAction}
      disabled={disabled}
    />
  ) : (
    <ShopInformationButton
      isVeggiePret={shop.features.storeType === storeTypes.veggiePret}
      styleType='tertiary'
      onClick={onClickAction}
      compact
    >
      <Translation id='storefinder.store.information' />
    </ShopInformationButton>
  )
}

MarkerModalActionButton.propTypes = {
  action: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  shop: storeProps,
  showSelectShopButton: PropTypes.bool
}

// Todo Extract out this button component and the button component used in store-list-item and use button with Loading-indicator once Loading indicator has modified as per TT-1417
const SelectShopButtonWithSpinner = ({ loading, onClick, disabled }) => {
  return (
    <SelectShopButtonContainer>
      {loading ? (
        <ActivitySpinner size='small' />
      ) : (
        <SelectShopButton
          disabled={disabled}
          styleType='tertiary'
          onClick={onClick}
        >
          <Translation id='storefinder.store.selectShop' />
        </SelectShopButton>
      )}
    </SelectShopButtonContainer>
  )
}

SelectShopButtonWithSpinner.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}
