import { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { getMarketDetailsFromLocale } from '#lib/get-market'
import { storeSearchByAddress, storeSearchByLocation } from './api'
import { useSwitch } from '#hooks'

const useShopSearch = (defaultShops = []) => {
  const { locale } = useRouter()
  const [hasSearched, toggleHasSearched] = useSwitch(false)
  const [isSearching, startSearching, stopSearching] = useSwitch(false)
  const [hasApiThrownError, apiThrownError, resetHasApiThrownError] =
    useSwitch(false)
  const [hasResultsByLocation, setHasResultsByLocation] = useState(false)
  const [address, setAddress] = useState('')
  const [shops, setShops] = useState(defaultShops)

  const preSearchAction = () => {
    startSearching()
    toggleHasSearched()
    setShops([])
    setHasResultsByLocation(false)
    resetHasApiThrownError()
  }

  const postSearchAction = results => {
    setShops(results)
  }

  const reset = () => {
    setShops([])
    setHasResultsByLocation(false)
    apiThrownError()
  }

  const search = useCallback(
    async e => {
      e.preventDefault()
      preSearchAction()
      try {
        const market = getMarketDetailsFromLocale(locale)
        const address = e.target.address.value
        setAddress(address)
        const results = await storeSearchByAddress({
          address,
          market: market.id
        })
        postSearchAction(results)
      } catch (e) {
        reset()
      } finally {
        stopSearching()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddress, setShops, locale]
  )

  const searchByLatLong = useCallback(async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        preSearchAction()
        setAddress('')
        try {
          const results = await storeSearchByLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          setHasResultsByLocation(true)
          postSearchAction(results)
        } catch (e) {
          reset()
        } finally {
          stopSearching()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHasResultsByLocation, setShops])

  return {
    search,
    searchByLatLong,
    isSearching,
    hasResultsByLocation,
    hasSearched,
    hasApiThrownError,
    address,
    shops
  }
}

export default useShopSearch
