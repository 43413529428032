import { forwardRef, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Chevron } from '@pretamanger/component-library'
import { BackButtonContainer } from './styles'
import Translation from '../../../components/translation'
import { useRouter } from 'next/router'

type BackButtonProps = {
  action?: () => void
  children?: ReactNode
}

const BackButton = forwardRef<HTMLButtonElement, BackButtonProps>(
  ({ children, action, ...props }, ref) => {
    const router = useRouter()
    const handleBack = () => {
      router.back()
    }
    return (
      <BackButtonContainer
        ref={ref}
        data-testid='back-button'
        styleType='tertiary'
        icon={<Chevron type='left' />}
        onClick={action || handleBack}
        {...props}
      >
        {children}
      </BackButtonContainer>
    )
  }
)

BackButton.displayName = 'BackButton'

BackButton.defaultProps = {
  children: <Translation id='common.button.back' />
}

export default BackButton
