import { useContext, useCallback, useState } from 'react'
import { OrderAheadBagContext } from '#src/common/context/order-ahead-bag'
import { fireFeatureClickEvent } from '#src/common/lib/events/publishers/analytics/fire-events'
import { useRouter } from 'next/router'
import { getCollectionPath } from '#src/common/lib/get-collection-path'

const useShopSelection = () => {
  const { locale, push: routerPush } = useRouter()
  const collectionPath = getCollectionPath(locale)
  const path = `${collectionPath}/collection-timeslots`
  const { updateFulfillmentDetails } = useContext(OrderAheadBagContext)
  const [updating, setUpdating] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  const selectShopForOrderAhead = useCallback(
    async (shop, position = 'top') => {
      if (!updating) {
        setUpdating(true)
        setActiveItem(shop.id)
        await updateFulfillmentDetails({
          fulfillingChannelKey: shop.id,
          orderType: 'collection'
        })
        fireFeatureClickEvent({
          type: 'widget',
          name: shop.name,
          position,
          destination: `${window.location.origin}/${locale}/${path}`
        })
        await routerPush(`/${path}`, `/${locale}/${path}`)
        setUpdating(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updating, activeItem, updateFulfillmentDetails]
  )

  return {
    activeItem,
    updating,
    selectShopForOrderAhead
  }
}

export default useShopSelection
