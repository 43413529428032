import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { storeProps } from '@proptypes'
import { translate } from '#src/common/components/translation'

import ShopFinderShopInformation from '#src/shop-finder/components/shop-finder-shop-information'

import { CloseButton, Cross, Details, DownPointer, Wrapper } from './styles'

const ShopFinderMapMarkerModal = ({
  onClose,
  onClickInfo,
  onClickStartOrder,
  loading,
  shop,
  displayClickAndCollect
}) => {
  const { locale } = useRouter()

  const handleClickInfo = e => {
    e.stopPropagation()
    onClickInfo(shop)
  }

  const handleStartOrder = e => {
    e.stopPropagation()
    onClickStartOrder(shop)
  }

  return (
    <Wrapper aria-expanded='true'>
      <CloseButton
        title={translate('storefinder.close.button', locale)?.value}
        onClick={onClose}
      >
        <Cross colour='grey-700' />
      </CloseButton>
      <Details>
        <ShopFinderShopInformation
          isModal
          shop={shop}
          onClickInfo={handleClickInfo}
          onClickStartOrder={handleStartOrder}
          displayClickAndCollect={displayClickAndCollect}
          loading={loading}
        />
      </Details>
      <DownPointer />
    </Wrapper>
  )
}

ShopFinderMapMarkerModal.propTypes = {
  onClose: PropTypes.func,
  onClickInfo: PropTypes.func.isRequired,
  onClickStartOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  shop: storeProps.isRequired,
  displayClickAndCollect: PropTypes.bool
}

ShopFinderMapMarkerModal.defaultProps = {
  onClose: () => {},
  loading: false,
  displayClickAndCollect: false
}

export default ShopFinderMapMarkerModal
