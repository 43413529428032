import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { location } from '@proptypes'
import {
  DEFAULT_ZOOM,
  getZoomLevel
} from '#src/shop-finder/components/shop-finder-map/util'
import shopFinderMapOptions from '#src/shop-finder/components/shop-finder-map/options'
import { useState } from 'react'

const Map = ({
  apiKey,
  center,
  children,
  className,
  defaultFitBounds,
  mapDimension,
  onClick,
  places,
  showPOI
}) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM)
  const [calculatedCenter, setCalculatedCenter] = useState(center)
  const handleMapsApi = map => {
    const { zoom: zoomMap, center: centerMap } = getZoomLevel(
      defaultFitBounds,
      mapDimension,
      places,
      map
    )
    setZoom(zoomMap)
    setCalculatedCenter(centerMap)
  }
  const options = showPOI ? {} : { options: shopFinderMapOptions }

  return (
    <div className={className} onClick={onClick}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        center={center || calculatedCenter}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={map => handleMapsApi(map)}
        {...options}
      >
        {children}
      </GoogleMapReact>
    </div>
  )
}

Map.defaultProps = {
  onClick: () => {},
  places: [],
  showPOI: false
}

Map.propTypes = {
  apiKey: PropTypes.string.isRequired,
  center: location,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultFitBounds: PropTypes.shape({
    ne: location,
    sw: location
  }),
  mapDimension: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }),
  onClick: PropTypes.func,
  places: PropTypes.arrayOf(location),
  showPOI: PropTypes.bool
}

export default Map
