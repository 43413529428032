import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { storeProps } from '@proptypes'
import { translate } from '#src/common/components/translation'
import { transformAddressToString } from '#src/common/lib/format-address'

import { formatDistanceToMiles } from './shop-formatter-util'
import {
  ShopMarkerDetails,
  ShopMarkerModalCloseButton,
  ShopMarkerModalCross,
  ShopMarkerModalDownPointer,
  ShopMarkerModalWrapper
} from './styles'
import ShopInformation from './shop-information'

const ShopMarkerModal = ({
  actionButton,
  onClose,
  showDistanceInformation,
  shop
}) => {
  const { locale } = useRouter()
  const address = transformAddressToString(shop.address)
  const distance = formatDistanceToMiles(shop.distance, locale)
  const openTime = shop.todaysHours
  return (
    <ShopMarkerModalWrapper aria-expanded='true'>
      <ShopMarkerModalCloseButton
        title={translate('storefinder.close.button', locale)?.value}
        onClick={onClose}
      >
        <ShopMarkerModalCross colour='grey-700' />
      </ShopMarkerModalCloseButton>
      <ShopMarkerDetails>
        <ShopInformation
          address={address}
          isModal
          name={shop.name}
          openTime={openTime}
          rightContent={showDistanceInformation && distance}
          footer={actionButton}
        />
      </ShopMarkerDetails>
      <ShopMarkerModalDownPointer />
    </ShopMarkerModalWrapper>
  )
}

ShopMarkerModal.propTypes = {
  actionButton: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  showDistanceInformation: PropTypes.bool,
  shop: storeProps.isRequired
}

ShopMarkerModal.defaultProps = {
  showDistanceInformation: true,
  onClose: () => {},
  onSelection: () => {}
}

export default ShopMarkerModal
