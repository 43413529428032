import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Button, Cross as PretCross } from '@pretamanger/component-library'

const modalWidth = 348

export const Wrapper = styled('div')`
  ${tw`bg-white m-3 p-4 absolute z-10 shadow`}
  width: ${modalWidth}px;
  left: -${modalWidth / 2 - 3}px; // nudge to the right to fully cover the pin
  bottom: 16px;

  li {
    ${tw`list-none`}
  }
`

export const CloseButton = styled(Button)`
  &&& {
    ${tw`p-2 flex float-right bg-white hover:bg-transparent absolute z-30`}
    top: 5px;
    right: 5px;

    span {
      ${tw`ml-1`}
    }
  }
`

export const Cross = styled(PretCross)`
  ${tw`inline px-1 cursor-pointer`}
`

export const Details = styled('div')`
  ${tw`pb-3 relative z-20 bg-white`}
`

export const DownPointer = styled('div')`
  ${tw`bg-white absolute z-10 shadow transform rotate-45`}
  bottom: -10px;
  left: ${modalWidth / 2}px;
  width: 20px;
  height: 20px;
`
