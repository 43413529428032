import { useCallback, useState } from 'react'

const useShopMarkerModal = () => {
  const [shopIndexForMarkerModal, setShopIndexForMarkerModal] = useState(-1)
  const [shopForMarkerModal, setShopForMarkerModal] = useState(null)
  const [isMarkerModalOpen, setShowMarkerShopModal] = useState(false)

  const showMarkerModal = useCallback((index, shop) => {
    setShopIndexForMarkerModal(index)
    setShopForMarkerModal(shop)
    setShowMarkerShopModal(true)
  }, [])

  const hideMarkerModal = () => {
    setShowMarkerShopModal(false)
    setShopIndexForMarkerModal(-1)
    setShopForMarkerModal(null)
  }

  return {
    isMarkerModalOpen,
    shopIndexForMarkerModal,
    shopForMarkerModal,
    showMarkerModal,
    hideMarkerModal
  }
}

export default useShopMarkerModal
