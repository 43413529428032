import { fitBounds } from 'google-map-react'

export const DEFAULT_ZOOM = 16

export const getMapBounds = (places, mapFunction) => {
  const bounds = new mapFunction.maps.LatLngBounds()
  places.forEach(place =>
    bounds.extend(new mapFunction.maps.LatLng(place.lat, place.lng))
  )
  return {
    ne: bounds.getNorthEast(),
    sw: bounds.getSouthWest()
  }
}

export const getZoomLevel = (
  defaultFitBounds,
  mapDimension,
  places,
  mapFunction
) => {
  if (places.length === 1) {
    return { zoom: DEFAULT_ZOOM, center: places[0] }
  }

  if (places.length > 0 && mapDimension) {
    const bounds = getMapBounds(places, mapFunction)
    return fitBounds(bounds, mapDimension)
  }

  if (defaultFitBounds && mapDimension) {
    return fitBounds(defaultFitBounds, mapDimension)
  }

  return { zoom: DEFAULT_ZOOM }
}
