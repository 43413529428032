import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { storeProps } from '@proptypes'
import { storeTypes } from '#constants'
import { formatDistanceToLocaleValue } from '#src/shop-finder/util/distance'
import ShopFinderShopInformation from '#src/shop-finder/components/shop-finder-shop-information'
import { translate } from '#src/common/components/translation'

import { Wrapper, Content, MapMarker } from './styles'

const ShopFinderShopListItem = ({
  index,
  shop,
  onClickInfo,
  onClickStartOrder,
  loading,
  displayClickAndCollect
}) => {
  const { locale } = useRouter()
  const distance = formatDistanceToLocaleValue(shop.distance, locale)
  const isVeggiePret = shop.features.storeType === storeTypes.veggiePret
  const pinDescription = translate(
    'storefinder.store.pin.description',
    locale,
    { index }
  )?.value

  const handleClickInfo = e => {
    e.stopPropagation()
    onClickInfo(shop)
  }

  const handleClickStartOrder = e => {
    e.stopPropagation()
    onClickStartOrder(shop)
  }

  return (
    <Wrapper>
      <Content data-testid='store-list-item'>
        <div className='flex flex-col items-center'>
          <MapMarker
            title={pinDescription}
            isVeggiePret={isVeggiePret}
            isClosed={!shop.isCurrentlyOpen}
          />
          <span className='block text-xs text-grey-700 whitespace-nowrap xs:mt-1'>
            {distance}
          </span>
        </div>
        <ShopFinderShopInformation
          shop={shop}
          onClickInfo={handleClickInfo}
          onClickStartOrder={handleClickStartOrder}
          loading={loading}
          displayClickAndCollect={displayClickAndCollect}
          index={index}
        />
      </Content>
    </Wrapper>
  )
}

ShopFinderShopListItem.propTypes = {
  index: PropTypes.number.isRequired,
  shop: storeProps.isRequired,
  onClickInfo: PropTypes.func.isRequired,
  onClickStartOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  displayClickAndCollect: PropTypes.bool
}

ShopFinderShopListItem.defaultProps = {
  loading: false,
  displayClickAndCollect: false
}

export default ShopFinderShopListItem
